import React from 'react'
import { TransitionProvider, TransitionViews } from 'gatsby-plugin-transitions'
import { PageProps } from 'gatsby'
import { CookiesProvider } from 'react-cookie'
import Menu from '~/components/Menu'

const Layout = ({ children, location }: PageProps) => (
  <>
    <CookiesProvider>
      <TransitionProvider
        location={location}
        mode="immediate"
        enter={{
          opacity: 0,
        }}
        usual={{
          opacity: 1,
        }}
        leave={{
          opacity: 0,
          config: {
            duration: 200,
          },
        }}
      >
        {location.pathname !== '/404/' &&
          location.pathname !== '/404' &&
          location.pathname !== '/obrigado' &&
          location.pathname !== '/obrigado/' && <Menu />}
        <TransitionViews>{children}</TransitionViews>
      </TransitionProvider>
    </CookiesProvider>
  </>
)

export default Layout
