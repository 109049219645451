import toolsIcon from '~/assets/img/svg/tools.svg'
import checkedBox from '~/assets/img/svg/checked-box.svg'
import car from '~/assets/img/svg/car.svg'
import clock from '~/assets/img/svg/clock.svg'
import zeroKmBox from '~/assets/img/svg/0km-box.svg'

export default [
  {
    icon: [toolsIcon],
    title: 'Segurança e credibilidade',
    description:
      'Revisões e manutenções preventivas de sua frota em redes autorizadas e/ou prestadores de serviço credenciados',
  },
  {
    icon: [checkedBox, car],
    title: 'Conforto e praticidade',
    description:
      'Veículos prontos para utilização, com gestão e acompanhamento dos trâmites de documentação e multas de sua frota.',
  },
  {
    icon: [clock],
    title: 'Gestão e previsibilidade completa',
    description:
      'Além de ter a visibilidade de toda sua frota contratada, acompanhando manutenção e multas, você conta com a previsibilidade de custos inclusos na contratação.',
  },
  {
    icon: [zeroKmBox, car],
    title: 'Você sempre com o novo',
    description:
      'Veículos de última geração, 0KM e seminovos com renovação automática, conforme pactuado em contrato.',
  },
]
