export default [
  {
    name: 'Assinatura de veículos',
    slug: '/assinatura-de-veiculos/',
  },
  {
    name: 'Como funciona',
    slug: '/#como-funciona',
  },
  {
    name: 'Planos',
    slug: '/#planos',
    subItems: [
      {
        name: 'Conheça nossos planos',
        slug: '/#planos',
      },
      {
        name: 'Benefícios e vantagens',
        slug: '/#beneficios',
      },
    ],
  },
  {
    name: 'Terceirização de frota',
    slug: '/terceirizacao-de-frota/',
    subItems: [
      {
        name: 'Porque contratar',
        slug: '/terceirizacao-de-frota/#porque-contratar',
      },
      {
        name: 'Vantagens',
        slug: '/terceirizacao-de-frota/#vantagens',
      },
    ],
  },
  {
    name: 'Dúvidas',
    slug: '/#duvidas',
  },
  {
    name: 'Sobre',
    slug: '/sobre/',
    subItems: [
      {
        name: 'Quem somos',
        slug: '/sobre/#quem-somos',
      },
      {
        name: 'Política de privacidade',
        slug: '/politica-de-privacidade/',
      },
      {
        name: 'Termos de uso',
        slug: '/termos-de-uso/',
      },
    ],
  },
  {
    name: 'Contato',
    slug: '/contato/',
    subItems: [
      {
        name: 'Fale conosco',
        slug: '/contato/',
      },
      {
        name: 'Atendimento',
        slug: '/contato/#atendimento',
      },
      {
        name: 'Trabalhe Conosco',
        slug: 'http://www.dimas.com.br/trabalhe-conosco',
        external: true,
      },
    ],
  },
]
