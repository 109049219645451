export default [
  {
    name: 'Assinatura de veículos',
    slug: '/assinatura-de-veiculos/',
  },
  {
    name: 'Como funciona',
    slug: '/#como-funciona',
  },
  {
    name: 'Planos',
    slug: '/#planos',
  },
  {
    name: 'Terceirização de frota',
    slug: '/terceirizacao-de-frota/',
  },
  {
    name: 'Dúvidas',
    slug: '/#duvidas',
  },
  {
    name: 'Contato',
    slug: '/contato/',
  },
]
