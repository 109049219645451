// extracted by mini-css-extract-plugin
export var active = "Menu-module--active--2tSHi";
export var bar1 = "Menu-module--bar1--a8wLL";
export var bar2 = "Menu-module--bar2--FqjlG";
export var bar3 = "Menu-module--bar3--srmqz";
export var close = "Menu-module--close--0XOHQ";
export var gatsbyFocusWrapper = "Menu-module--gatsby-focus-wrapper--YWtLx";
export var hamburguerMenu = "Menu-module--hamburguer-menu--SJZr8";
export var hideOnDesktop = "Menu-module--hide-on-desktop--R+oNC";
export var hideOnMobile = "Menu-module--hide-on-mobile---Uv+j";
export var iconCar = "Menu-module--icon-car--Lfp3R";
export var iconCars = "Menu-module--icon-cars--mLFD2";
export var iconClock = "Menu-module--icon-clock--sakDm";
export var iconEletricCar = "Menu-module--icon-eletric-car--aWGXX";
export var iconFacebook = "Menu-module--icon-facebook--ODa8i";
export var iconGear = "Menu-module--icon-gear--ylRfS";
export var iconInstagram = "Menu-module--icon-instagram--CfB7-";
export var iconKm = "Menu-module--icon-km---S94L";
export var iconPhone = "Menu-module--icon-phone--IKOPt";
export var iconRight = "Menu-module--icon-right--nz-Cz";
export var iconWhatsapp = "Menu-module--icon-whatsapp--HHMxO";
export var logo = "Menu-module--logo--T6nnT";
export var menuActive = "Menu-module--menu-active--ITO5C";
export var menuContainer = "Menu-module--menu-container--TxP4U";
export var menuItem = "Menu-module--menu-item--GDHkU";
export var menuNav = "Menu-module--menu-nav--m+GC7";
export var mobileMenu = "Menu-module--mobile-menu--1dKwp";
export var pageWidth = "Menu-module--page-width--hDQoh";
export var path1 = "Menu-module--path1--ZVOxa";
export var path2 = "Menu-module--path2--voQKf";
export var path3 = "Menu-module--path3--BtPbj";
export var top = "Menu-module--top--G8DtD";
export var view = "Menu-module--view--U7mQd";
export var views = "Menu-module--views--89vby";