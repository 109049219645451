import React, { useEffect, useRef, useState } from 'react'
import cn from 'classnames'

import * as st from '~/assets/styl/Menu.module.styl'
import { menuItems } from '~/data'
import { instagram } from '~/utils/socials.js'
import Link from './Link'

import dimasLogo from '~/assets/img/dimas-logo.svg'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { LazyImage } from 'react-lazy-images'

export default function Menu() {
  const [navbarOpen, setNavbarOpen] = useState(false)
  const [top, setTop] = useState(true)
  const clickBtn = useRef<HTMLButtonElement>()

  useEffect(() => {
    const onScroll = () => {
      setTop(window.scrollY <= 0)
    }
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  })

  function handleToggle() {
    setNavbarOpen(!navbarOpen)
  }

  function handleClose() {
    setNavbarOpen(false)
  }

  return (
    <header
      className={cn(st.menuContainer, top && st.top, {
        [st.menuActive]: navbarOpen,
      })}
    >
      <AnchorLink to="/">
        <LazyImage
          src={dimasLogo}
          className={st.logo}
          placeholder={({ ref }) => <img ref={ref} src={dimasLogo} />}
          actual={({ imageProps }) => <img {...imageProps} />}
        />
      </AnchorLink>
      <button
        className={cn(st.hamburguerMenu, 'hide-on-desktop')}
        onClick={handleToggle}
      >
        <div className={st.bar1}></div>
        <div className={st.bar2}></div>
        <div className={st.bar3}></div>
      </button>
      <div
        className={cn(st.mobileMenu, 'hide-on-desktop', {
          [st.active]: navbarOpen,
        })}
      >
        <nav>
          <button
            onClick={() => setNavbarOpen(false)}
            ref={clickBtn}
            className={st.close}
          ></button>
          <ul>
            {menuItems.map((item, key) => (
              <li key={key}>
                <Link
                  onClick={() => clickBtn.current.click()}
                  href={item.slug}
                  className={st.menuItem}
                >
                  {item.name}
                </Link>
              </li>
            ))}
            <li>
              <a
                href={instagram}
                target="_blank"
                rel="noreferrer external nofollow"
                className={st.menuItem}
              >
                <span className="icon-instagram"></span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <nav className="hide-on-mobile">
        <ul className={st.menuNav}>
          {menuItems.map((item, key) => (
            <li key={key}>
              <AnchorLink to={item.slug} className={st.menuItem}>
                {item.name}
              </AnchorLink>
            </li>
          ))}
          <li>
            <a
              href={instagram}
              target="_blank"
              rel="noreferrer external nofollow"
              className={st.menuItem}
            >
              <span className="icon-instagram"></span>
            </a>
          </li>
        </ul>
      </nav>
    </header>
  )
}
