export default [
  {
    year: '1970',
    description:
      'A história do Grupo Dimas começa em 1976 com a fundação da loja Multimarcas Dimas, que comercializa veículos novos e usados, sempre buscando a satisfação total dos clientes - o sonho dos fundadores Dimas Arnoldo da Silva e sua esposa Maria Lenir.',
  },
  {
    year: '1980',
    description:
      'Em 1985, em menos de dez anos da sua fundação, as Empresas Dimas diversificam seus negócios ao firmar alicerces na construção civil, tornando-se referência de alto padrão e qualidade até hoje. Um exemplo é o Dimas Park Hotel, que atualmente chama-se Brisamar Hotel - uma estrutura de 80 apartamentos junto ao mar na parte continental de Florianópolis.',
  },
  {
    year: '1980',
    description:
      'No mesmo ano, a Dimas Ford Campinas é fundada e passa a ser a Distribuidora Ford oficial da região, vendendo acessórios, peças originais e serviços de oficina, além dos veículos novos e usados. Um ano se passa e nasce a Dimas Ford Caminhões em São José-SC, com instalações amplas e oferecendo serviços diversos para veículos pesados.',
  },
  {
    year: '1990',
    description:
      'A década de 90 é marcada pela abertura de mais uma loja, a Dimas Ford Estreito, e pelo investimento em um novo negócio: a Dimas Consórcio.',
  },
  {
    year: '2000',
    description:
      'Em 2001 e 2005 foram abertas mais duas lojas na região catarinense: a Dimas Ford SC-401 e a Dimas Ford Tijucas. Em 2006, a Dimas surpreende novamente e passa a ser o distribuidor Hyundai no mercado de Santa Catarina, com a fundação da Dimas Hyundai Estreito, exibindo uma arquitetura moderna e estrutura completa de concessionária.',
  },
  {
    year: '2000',
    description:
      'A cidade de São José recebeu a Dimas de braços abertos: em 2007 com a abertura da Central de Usados, uma assistência de serviços automotivos; e em 2008 com a unidade de Repasse, que tem o objetivo de repassar os veículos que não estão em condições de serem vendidos nas lojas.',
    bottom:
      'A conquista da bandeira Volvo Cars em 2013 faz a Dimas Volvo ser representante exclusivo da marca sueca de veículos de luxo em SC.',
  },
  {
    year: '2000',
    description:
      'De 2009 a 2014, a empresa expandiu seus horizontes ainda mais. A abertura das lojas nas cidades de São Bento do Sul, Mafra, Palhoça, Joinville, Brusque e Itajaí, além do bairro Jardim Atlântico de Florianópolis, refletem o espírito empreendedor de seus proprietários e o comprometimento de todos os colaboradores que não medem esforços para atender e conquistar nossos clientes.',
    bottom:
      'Em 2016, as Empresas Dimas completaram 40 anos de história. Sinônimo de credibilidade, segurança e confiança em todos os segmentos em que atua - um patrimônio construído ao longo desta história de sucesso, do qual todos nós fazemos parte.',
  },
]
