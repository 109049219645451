import soraya from '../assets/img/soraya.jpg'
import jessica from '../assets/img/jessica.jpg'
import david from '../assets/img/david.jpg'
import matheus from '../assets/img/matheus.jpg'

export default [
  {
    name: 'Soraya Furtado da Rosa',
    role: 'Head Dimas Sempre',
    image: soraya,
  },
  {
    name: 'Jéssica Carneiro',
    role: 'Consultora de negócios',
    image: jessica,
  },
  {
    name: 'David da Silva',
    role: 'Consultor de negócios',
    image: david,
  },
  {
    name: 'Matheus Santos de Lima',
    role: 'Vendedor Trainee',
    image: matheus,
  },
]
