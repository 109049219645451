import ThreeCars from '~/assets/img/svg/3-cars.svg'
import gears from '~/assets/img/svg/gears.svg'
import zeroKm from '~/assets/img/svg/0km.svg'

export default [
  {
    id: 1,
    description: 'Escolha seu veículo',
    icon: ThreeCars,
  },
  {
    id: 2,
    description: 'Personalize e simule seu plano',
    icon: gears,
  },
  {
    id: 3,
    description: 'Saia de carro 0 km',
    icon: zeroKm,
  },
]
