exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-assinatura-de-veiculos-tsx": () => import("./../../../src/pages/_assinatura-de-veiculos.tsx" /* webpackChunkName: "component---src-pages-assinatura-de-veiculos-tsx" */),
  "component---src-pages-contato-tsx": () => import("./../../../src/pages/contato.tsx" /* webpackChunkName: "component---src-pages-contato-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/_index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-obrigado-tsx": () => import("./../../../src/pages/obrigado.tsx" /* webpackChunkName: "component---src-pages-obrigado-tsx" */),
  "component---src-pages-politica-de-privacidade-tsx": () => import("./../../../src/pages/politica-de-privacidade.tsx" /* webpackChunkName: "component---src-pages-politica-de-privacidade-tsx" */),
  "component---src-pages-sobre-tsx": () => import("./../../../src/pages/sobre.tsx" /* webpackChunkName: "component---src-pages-sobre-tsx" */),
  "component---src-pages-terceirizacao-de-frota-tsx": () => import("./../../../src/pages/terceirizacao-de-frota.tsx" /* webpackChunkName: "component---src-pages-terceirizacao-de-frota-tsx" */),
  "component---src-pages-termos-de-uso-tsx": () => import("./../../../src/pages/termos-de-uso.tsx" /* webpackChunkName: "component---src-pages-termos-de-uso-tsx" */)
}

